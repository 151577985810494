<template>
  <div>
    <head-bar title="Profiel verwijderen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="profile">
      <form class="p-2 p-md-3 mb-3 text-center" @submit.prevent="onSubmit">
        <div class="form-group">
          <label for="reason">
            Wat jammer dat je het profiel wilt verwijderen. Kun je ons vertellen
            waarom je geen gebruik meer van de Kwispelcoach wilt maken? Zo kunnen
            we je feedback gebruiken om de Kwispelcoach te verbeteren. Alvast bedankt!
          </label>

          <select
            class="select-control"
            name="reason"
            id="reason"
            @change="onReasonChange"
          >
            <option value="">Maak een keuze</option>
            <option value="Mijn huisdier is inmiddels volwassen">
              Mijn huisdier is inmiddels volwassen
            </option>
            <option value="Ik heb geen hond of kat meer">
              Ik heb geen hond of kat meer
            </option>
            <option value="De informatie vind ik niet interessant genoeg">
              De informatie vind ik niet interessant genoeg
            </option>
            <option value="app">
              Ik vind de app niet fijn werken
            </option>
            <option value="other">
              Anders, namelijk...
            </option>
          </select>
        </div>

        <div class="form-group" v-if="reasonActive">
          <label for="reason_text">
            Toelichting
          </label>
          <textarea
            class="form-control"
            name="reason_text"
            id="reason_text"
            rows="3"
          ></textarea>
        </div>

        <button type="submit" class="btn btn--primary">
          Verwijder mijn profiel
        </button>
      </form>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import AppLayout from '../AppLayout.vue';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    HeadBarLink,
    HeadBar,
    AppLayout,
  },

  data() {
    return {
      errors: {},
      serverErrors: [],
      reasonActive: false,
    };
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onReasonChange(event) {
      const { value } = event.currentTarget;

      this.reasonActive = (value === 'app' || value === 'other');
    },

    onSubmit(event) {
      const formData = new FormData(event.currentTarget);

      this.errors = {};

      if (this.reasonActive) {
        const reason = `${formData.get('reason')} - ${formData.get('reason_text')}`;

        formData.set('reason', reason);
      }

      if (!Object.keys(this.errors).length) {
        // eslint-disable-next-line no-alert
        if (!window.confirm('Weet je het zeker?')) {
          return;
        }

        this.$store.dispatch('removeUser', formData)
          .then(() => {
            this.$router.push({ name: 'Login' });
            this.toast.success('Je profiel is verwijderd.', {
              timeout: 2500,
            });
          });
      }
    },
  },

  computed: {
    ...mapGetters([
      'user',
    ]),

    address() {
      return this.user ? this.user.address : {};
    },
  },
};
</script>

<style>
.app-layout.profile {
  background-color: #fff;
  padding-top: 3.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  font-weight: 500;
  color: var(--purple);
  margin-bottom: .375rem;
  display: block;
}

.form-error {
  color: var(--primary);
  margin-top: .5rem;
  font-weight: 500;
}
</style>
