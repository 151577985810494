<template>
  <button type="button" class="btn-reset">
    <slot />
  </button>
</template>

<script>
export default {
  methods: {
    //
  },
};
</script>
