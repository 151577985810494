<template>
  <div class="head-bar">
    <slot name="left"></slot>

    <div class="head-bar__title" v-if="title">
      {{ title }}
    </div>

    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style>
.head-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  background-color: #fff;
  z-index: 1;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .25rem .875rem;
  border-radius: 0 0 .875rem .875rem;
}

.head-bar .btn-reset {
  color: var(--purple);
  font-weight: 500;
}

.head-bar .btn-reset:focus:not(:focus-visible) {
  outline: none;
}

.head-bar .btn-reset img:first-child {
  margin-right: .25rem;
}

.head-bar__title {
  color: var(--purple);
  font-size: 1.0625rem;
  font-weight: 500;
  text-align: center;
  flex-grow: 1;
}

.head-bar__title:first-child {
  padding-left: 2rem;
}

.head-bar__title:last-child {
  padding-right: 2rem;
}

@media (min-width: 640px) {
  .head-bar {
    width: var(--max-container-width);
    left: 50%;
    margin-left: calc(var(--max-container-width) / 2 * -1);
    box-shadow: 0 2px 10px -3px rgba(0, 0, 0, .2);
  }
}
</style>
